<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <!-- 控制区域 -->
      <div class="control_box">
        <Search-bar :timeRange="timeRange" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0 || loading">导出</a-button>
          <div class="info-tips">【用户账户报告】为消费预收统计，包含已报餐还未就餐的统计，如需查看就餐实收统计，请查看【用户消费报告】</div>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :loading="loading" :columns="columns" :data-source="tableData" bordered :pagination="pagination"
          @change="handleTableChange" :scroll="{ x: 2000, y: 500 }"></a-table>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getPresentationReport, exportPresentationReport, userLog } from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SearchBar, MyBreadcrumb },
  data () {
    return {
      breadcrumbs: [
        {
          key: 0,
          title: "经营报表",
        },
        {
          key: 1,
          title: "用户账户报告",
        },
      ],
      timeRange: {
        show: true,
        placeholderStart: "开始时间(默认上月)",
        placeholderEnd: "结束时间(默认上月)",
        rangeLimit: true
      },
      queryParam: {
        page: 1,
        begin_date: "",
        end_date: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      tableDataLength: 0,
      exportToExcelData: [],
      loading: false,
      columns: [], // 表格的列配置
      pagination: {
        total: 0,
        pageSize: 11, //接口返回10条数据  加上接口返回之后前端处理添加的一条合计数据总11条
        current: 1,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //列表下载
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.export2Excel();
        },
        onCancel: () => { },
      });
    },
    //数据写入excel
    export2Excel () {

      let startTime = this.getLastMonthStartDate();
      let endTime   = this.getLastMonthEndDate();
      import("@/views/operatingStatement/userAccountExport2Excel").then(
        (excel) => {
          const interval = [
            [
              "#起始日期",
              "",
              "",
              "",
              startTime,
              "",
              "",
              "",
              "#终止日期",
              "",
              "",
              "",
              endTime,
              "",
              "",
              "",
              ""
            ]
          ];
          const multiHeader = [
            [
              "基础信息",
              "",
              "",
              "",
              "",
              "账户充值",
              "",
              "",
              "退款",
              "",
              "账户调减",
              "",
              "",
              "消费交易扣款",
              "",
              "",
              "期末余额",
            ], //第一行
          ]; // 前两行的表头数据，二维数组，不够的用空白补全
          const header = [
            "部门",
            "职务",
            "工号",
            "姓名",
            "期初余额",
            "虚拟值批量充值",
            "虚拟值个别充值",
            "微信报餐充值",
            "取消报餐退款",
            "取消商城订单退款",
            "虚拟值批量调减",
            "虚拟值个别调减",
            "微信退餐取现",
            "报餐",
            "临时消费码",
            "商城",
            "",
          ]; // 最后一行的表头数据
          const filterVal = [
            "ftitle",
            "ptitle",
            "work_id",
            "realname",
            "monthf_money",
            "p_money",
            "g_money",
            "w_money",
            "qx_money",
            "xct_money",
            "pt_money",
            "gt_money",
            "wt_money",
            "bc_money",
            "ls_money",
            "xc_money",
            "monthe_money",
          ];
          const list = this.exportToExcelData;
          const data = this.formatJson(filterVal, list);
          const merges = [
            "A1:D1",
            "E1:H1",
            "I1:L1",
            "M1:Q1",
            "A2:E2",
            "F2:H2",
            "I2:J2",
            "C2:F2",
            "K2:M2",
            "N2:P2",
            "Q2:Q3",
          ]; // 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
          excel.export_json_to_excel({
            interval,
            multiHeader,
            header,
            merges,
            data,
            filename: "用户账户报告",
          });
          this.userLogFn()
        }
      );
    },
    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    // 刷新
    search (val) {
      this.queryParam = {
        page: 1,
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
      };
      this.pagination.current = 1;
      this.getTableData();
    },
    //获取表格数据
    getTableData () {
      this.loading = true;
      //获取分页的数据用于展示
      getPresentationReport(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total + Math.ceil(resultData.total / 10);
        this.pagination = pagination;
        if (resultData.list.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }

        let formatData = resultData.list,
          key = 1;
        //处理接口table数据
        let totalObj = {
          work_id: "",
          realname: "",
          ptitle: "",
          ftitle: "合计",
          monthf_money:
            resultData.res[0].z_monthf_money == null
              ? "-"
              : resultData.res[0].z_monthf_money, //期初余额

          //账户充值
          p_money: resultData.res[0].p_sum, //虚拟值批量充值
          g_money: resultData.res[0].g_sum, //虚拟之个别充值
          w_money: resultData.res[0].w_sum, //微信报餐充值

          //退款
          qx_money: resultData.res[0].qx_sum, //取消报餐退款
          xct_money: resultData.res[0].xct_sum, //取消商城订单退款

          //账户调减
          pt_money: resultData.res[0].pt_sum, //虚拟值批量调减
          gt_money: resultData.res[0].gt_sum, //虚拟值个别调减
          wt_money: resultData.res[0].wt_sum, //微信退餐取现

          //消费交易扣款
          bc_money: resultData.res[0].bc_sum, //报餐
          ls_money: resultData.res[0].ls_sum, //临时消费码
          xc_money: resultData.res[0].xc_sum, //商城

          monthe_money:
            resultData.res[0].z_monthe_money == null
              ? "-"
              : resultData.res[0].z_monthe_money, //期末余额
        };
        formatData.push(totalObj);
        formatData.map((item) => {
          item.key = key++;
        });

        this.loading = false;
        this.tableData = formatData;
        this.tableDataLength = formatData.length;

        //配置表格列头
        this.columns = [
          {
            title: "基础信息",
            children: [
              {
                title: "部门",
                align:'center',
                dataIndex: "ftitle",
                customRender: (value, row, index) => {
                  let tableLength = this.tableDataLength;
                  const obj = {
                    children: value,
                    attrs: {},
                  };
                  if (row.key === tableLength) {
                    obj.attrs.colSpan = 4;
                  }
                  return obj;
                },
              },
              {
                title: "职务",
                align:'center',
                dataIndex: "ptitle",
                customRender: (value, row, index) => {
                  let tableLength = this.tableDataLength;
                  const obj = {
                    children: value,
                    attrs: {},
                  };
                  if (row.key === tableLength) {
                    obj.attrs.colSpan = 0;
                  }
                  return obj;
                },
              },
              {
                title: "工号",
                align:'center',
                dataIndex: "work_id",
                customRender: (value, row, index) => {
                  let tableLength = this.tableDataLength;
                  const obj = {
                    children: value,
                    attrs: {},
                  };
                  if (row.key === tableLength) {
                    obj.attrs.colSpan = 0;
                  }
                  return obj;
                },
              },
              {
                title: "姓名",
                align:'center',
                dataIndex: "realname",
                customRender: (value, row, index) => {
                  let tableLength = this.tableDataLength;
                  const obj = {
                    children: value,
                    attrs: {},
                  };
                  if (row.key === tableLength) {
                    obj.attrs.colSpan = 0;
                  }
                  return obj;
                },
              },
              {
                title: "期初余额",
                align:'center',
                dataIndex: "monthf_money",
              },
            ],
          },
          {
            title: "账户充值",
            children: [
              {
                title: "虚拟值批量充值",
                ellipsis:true,
                // width:175,//175就是限制10个字的长度
                align:'center',
                dataIndex: "p_money",
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'虚拟值批量充值'
                    }
                  }
                }
              },
              {
                title: "虚拟值个别充值",
                ellipsis:true,
                align:'center',
                dataIndex: "g_money",
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'虚拟值个别充值'
                    }
                  }
                }
              },
              {
                title: "微信报餐充值",
                align:'center',
                dataIndex: "w_money",
              },
            ],
          },
          {
            title: "退款",
            children: [
              {
                title: "取消报餐退款",
                ellipsis:true,
                align:'center',
                dataIndex: "qx_money",
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'取消报餐退款'
                    }
                  }
                }
              },
              {
                title: "取消商城订单退款",
                ellipsis:true,
                align:'center',
                dataIndex: "xct_money",
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'取消商城订单退款'
                    }
                  }
                }
              },
            ],
          },
          {
            title: "账户调减",
            children: [
              {
                title: "虚拟值批量调减",
                ellipsis:true,
                align:'center',
                dataIndex: "pt_money",
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'虚拟值批量调减'
                    }
                  }
                }
              },
              {
                title: "虚拟值个别调减",
                ellipsis:true,
                align:'center',
                dataIndex: "gt_money",
                customHeaderCell: (record, index) => {
                  return {
                    attrs:{
                      title:'虚拟值个别调减'
                    }
                  }
                }
              },
              {
                title: "微信退餐取现",
                align:'center',
                dataIndex: "wt_money",
              },
            ],
          },
          {
            title: "消费交易扣款",
            children: [
              {
                title: "报餐",
                align:'center',
                dataIndex: "bc_money",
              },
              {
                title: "临时消费码",
                align:'center',
                dataIndex: "ls_money",
              },
              {
                title: "商城",
                align:'center',
                dataIndex: "xc_money",
              },
            ],
          },
          {
            title: "期末余额",
            align:'center',
            dataIndex: "monthe_money",
          },
        ];
      });
      //获取不带分页的数据用于导出
      exportPresentationReport(this.queryParam).then((res) => {
        let resultData = res.data;

        if (resultData.list.length == 0) {
          this.exportToExcelData = [];
          return;
        }

        let formatData = [];
        for (let key in resultData.list) {
          formatData.push(resultData.list[key]);
        }
        //处理接口table数据
        let totalObj = {
          work_id: "",
          realname: "",
          ptitle: "",
          ftitle: "合计",
          monthf_money:
            resultData.res[0].z_monthf_money == null
              ? "-"
              : resultData.res[0].z_monthf_money, //期初余额

          //账户充值
          p_money: resultData.res[0].p_sum, //虚拟值批量充值
          g_money: resultData.res[0].g_sum, //虚拟之个别充值
          w_money: resultData.res[0].w_sum, //微信报餐充值

          //退款
          qx_money: resultData.res[0].qx_sum, //取消报餐退款
          xct_money: resultData.res[0].xct_sum, //取消商城订单退款

          //账户调减
          pt_money: resultData.res[0].pt_sum, //虚拟值批量调减
          gt_money: resultData.res[0].gt_sum, //虚拟值个别调减
          wt_money: resultData.res[0].wt_sum, //微信退餐取现

          //消费交易扣款
          bc_money: resultData.res[0].bc_sum, //报餐
          ls_money: resultData.res[0].ls_sum, //临时消费码
          xc_money: resultData.res[0].xc_sum, //商城

          monthe_money:
            resultData.res[0].z_monthe_money == null
              ? "-"
              : resultData.res[0].z_monthe_money, //期末余额
        };
        formatData.push(totalObj);

        this.exportToExcelData = formatData;
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            begin_date:this.queryParam.begin_date,
            end_date:this.queryParam.end_date,
            total:this.exportToExcelData.length
          };
      userLog(moduleId,param).then(res => {}).catch(err => {
        console.log(err)
      })
    },
    getLastMonthStartDate() {
      if( this.queryParam.begin_date.length != 0 ){
        return this.queryParam.begin_date;
      }
      let now = new Date();
      let lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      let formattedDate = lastMonth.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return formattedDate;
    },
    getLastMonthEndDate() {
      if( this.queryParam.end_date.length != 0 ){
        return this.queryParam.end_date;
      }
      let now = new Date();
      let lastDayOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 0);
      // return lastDayOfThisMonth.toISOString().substring(0, 10);
      let formattedDate = lastDayOfThisMonth.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return formattedDate;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
      display: flex;
      justify-self: start;
    }
    .info-tips{
      line-height: 32px;
      width: 100%;
      text-align: center;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>